import React from 'react';
import Layout from '../components/Layout';

export const BeerPageTemplate = () => {
  return (
    <iframe src="https://business.untappd.com/embeds/iframes/45437/171119" frameborder="0" height="2000" width="100%"></iframe>
  );
}

const BeerPage = () => {
  return (
    <Layout>
      <BeerPageTemplate />
    </Layout>
  );
}

export default BeerPage;
